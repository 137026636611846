import React, {useEffect, useState} from 'react'

import quote_icon from "../img/icon/double-quote.png";


function MiniNews({news}) {
  const [contents, setContents] = useState('')
  const [title, setTitle] = useState('')


  useEffect(() => {
    if (news) {
      if (news.contents) {
        setContents(news.contents)
      }

      if (news.title) {
        setTitle(news.title)
      }
    }
  }, [news])


	return (
		<div className="mini chart container">
      <div className="mini chart inside-container">
        <img alt="news decor" className="mini news decor" src={quote_icon} />
        <div className="mini news title">
          {title.toUpperCase()}
        </div>

        <div className="mini news contents">
          {contents}
        </div>
      </div>
		</div>
	)
}


export default MiniNews