const baseline = {
  male: {
    20: {right: [100.4, 141.6], left: [82.7, 126.3]},
    25: {right: [97.8, 143.8], left: [94.3, 126.7]},
    30: {right: [99.4, 144.2], left: [88.7, 131.7]},
    35: {right: [95.7, 143.7], left: [91.2, 134.6]},
    40: {right: [96.1, 137.5], left: [94.1, 131.5]},
    45: {right: [86.9, 132.9], left: [78, 123.6]},
    50: {right: [95.5, 131.7], left: [84.9, 118.9]},
    55: {right: [74.4, 127.8], left: [59.8, 106.6]},
    60: {right: [69.3, 110.1], left: [56.5, 97.1]},
    65: {right: [70.5, 111.7], left: [57, 96.9]},
    70: {right: [53.8, 96.8], left: [46.7, 82.9]},
    75: {right: [44.7, 86.7], left: [38, 72]},
  },

  female: {
    20: {right: [55.9, 84.9], left: [47.9, 74.1]},
    25: {right: [60.6, 88.4], left: [51.3, 75.7]},
    30: {right: [59.5, 97.9], left: [50.3, 85.7]},
    35: {right: [63.3, 84.9], left: [54.6, 78]},
    40: {right: [56.9, 83.9], left: [48.5, 76.1]},
    45: {right: [47.1, 77.3], left: [43.3, 68.7]},
    50: {right: [53.9, 77.4], left: [46.6, 68]},
    55: {right: [44.8, 69.8], left: [35.4, 59.2]},
    60: {right: [45.0, 65.2], left: [35.6, 55.8]},
    65: {right: [39.9, 59.3], left: [32.8, 49.2]},
    70: {right: [37.9, 61.3], left: [31.3, 51.7]},
    75: {right: [31.6, 53.6], left: [28.7, 46.5]},
  },
}


export default baseline