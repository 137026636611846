import React,{useEffect,useState} from 'react'
import {post} from '../lib/helpers'

// import Map from './Map'


function Locations({user, showMap=true}) {
  const [location, setLocation] = useState({})

  useEffect(() => {
    post('member/location', {location_id:user.location_id})
    .then(data=>{
      if (data.address) data.address = JSON.parse(data.address)
      if (data.contact) data.contact = JSON.parse(data.contact)

      setLocation(data)
    })
    .catch(err=>console.error(err))
  }, [user])

  function Address({data}) {
    if (!data) return <></>

    const URI = `geo:${location.latitude},${location.longitude}}`
    const linkText = `${data.street} ${data.city}, ${data.state} ${data.zip}`

    return <h4 className="row justify-content-center col-auto">
      <a href={URI}>{linkText}</a>
    </h4>
  }

  function Contact({data}) {
    try {
      const phoneNumber = data.phone.slice(0,data.phone.length-7)+' '+data.phone.slice(data.phone.length-7,data.phone.length-4)+' '+data.phone.slice(-4)

      const URI = `tel:+${phoneNumber}`
      
      return <h4 className="row justify-content-center col-auto">
        <a href={URI}>{phoneNumber}</a>
      </h4>
    } catch (error) {
      
    }

    return <h4>Invalid Phone Number</h4>
  }

  return (
    <section>
      <h3 className="row justify-content-center col-auto">{location.name}</h3>
      <Address data={location.address} />
      <Contact data={location.contact} />
      {/* {showMap ? <Map location={location} /> : <></>} */}
    </section>
  )
}

export default Locations