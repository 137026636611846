import React,{useEffect} from 'react'
import {heightString, post, weightString} from './lib/helpers'
import useSessionStorage from "./hook/useSessionStorage";

function Profile({user}) {
  const [data, setData] = useSessionStorage('member-stats', [])

  useEffect(() => {
    let body = {user_id:user.id}
    
    post('member/stats', body)
    .then(result=>{
      setData(result)
    })
    .catch(error=>console.error(error))
    
    return () => { }
  }, [user])
  
  
  function Stat({name,value}){
    return (
      <tr className="stat">
        <td className="stat label">{name.capitalize()}</td>
        <td className="stat value">{value}</td>
      </tr>
    )
  }

  const toLocalDate = (dateStr)=>{
    return (new Date(dateStr)).toLocaleDateString()
  }

  return (
    <section className="stats">
      <h3 className="row justify-content-center col-auto">Your OsteoStrong Profile</h3>
      <div className="row justify-content-center col-auto">
        <table className="center stats">
          <tbody>
            <Stat name="name" value={user.name} />
            <Stat name="location" value={user.location} />
            <Stat name="email" value={user.email} />
            <Stat name="phone" value={user.phone} />
            {/* <Stat name="login_id" value={user.login_id} /> */}

            <Stat name="birthyear" value={data.birthyear} />
            {/* <Stat name="exercise" value={data.exercise} /> */}
            <Stat name="fitness" value={data.fitness} />
            <Stat name="gender" value={data.gender} />
            <Stat name="startdate" value={toLocalDate(data.startdate)} />
            {/* <Stat name="units" value={data.units} /> */}
            <Stat name="height" value={heightString(data.height, data.units)} />
            <Stat name="weight" value={weightString(data.weight, data.units)} />
          </tbody>
        </table>
      </div>
      <br/>
      <h5>*If anything needs updated please let us know at your next session!</h5>
    </section>
  )
}

export default Profile