import React, {useEffect, useState} from 'react'
import { distance } from "../../lib/helpers";
import TestViz from './TestViz'


export default function Balance({
  breakAfter=true,
  data,
}) {
  const height = 480
  const width = 640
  
  const center = {x: width/2, y: height/2}

  const [accuracy, setAccuracy] = useState({
    value: 100,
    x: center.x,
    y: center.y,
  })
  const [points, setPoints] = useState([])
  const [precision, setPrecision] = useState({
    value: 100,
    x: (center.x) + 100,
    y: (center.y) + 100,
  })
  
  
  return <>
    <TestViz
      data={data}
      height={height}
      index={1}
      title="Center Balance"
      width={width}
    />
    
    <TestViz
      data={data}
      height={height}
      index={0}
      title="Right Balance"
      width={width}
    />
    
    <TestViz
      data={data}
      height={height}
      index={2}
      title="Left Balance"
      width={width}
    />
  </>
}