import React,{useEffect} from 'react'
import {useNavigate} from 'react-router-dom'


function Logout({auth,setAuth,setUser}) {
  const navigate = useNavigate()


  useEffect(() => {
    sessionStorage.clear()
    localStorage.removeItem('credentials')
    setAuth(false)
    setUser({})
  })

  useEffect(() => {
    if (!auth) navigate('/')
  }, [auth])


  return <></>
}


export default Logout