import React,{useEffect,useState} from 'react'


function OverallStats({children, data, user}) {
	const [firstSession, setFirstSession] = useState('')
  const [functionalBoneIncrease, setFunctionalBoneIncrease] = useState(0)
  const [, setNextLevelSessions] = useState(100)
  const [totalSessions, setTotalSessions] = useState(0)
  const [username, setUsername] = useState('Member')

  
  useEffect(() => {
    if (user && user.name) setUsername(user.name)
  }, [user])


  useEffect(() => {
    if (data && data.lower) {
      // Functional Bone Increase
      // const coreMax = Math.min.apply(null, data.core)
      const coreMax = data.core.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const lowerMax = data.lower.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const posturalMax = data.postural.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const upperMax = data.upper.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)

      let coreMin = 0
      if (data.core && data.core.length) coreMin = data.core.sort((a,b)=>{
        if (a.timestamp > b.timestamp) return 1
        if (a.timestamp < b.timestamp) return -1
        else return 0
      })[0].mob

      let lowerMin = 0
      if (data.lower && data.lower.length) lowerMin = data.lower.sort((a,b)=>{
        if (a.timestamp > b.timestamp) return 1
        if (a.timestamp < b.timestamp) return -1
        else return 0
      })[0].mob

      let posturalMin = 0
      if (data.postural && data.postural.length) posturalMin = data.postural.sort((a,b)=>{
        if (a.timestamp > b.timestamp) return 1
        if (a.timestamp < b.timestamp) return -1
        else return 0
      })[0].mob

      let upperMin = 0
      if (data.upper && data.upper.length) upperMin = data.upper.sort((a,b)=>{
        if (a.timestamp > b.timestamp) return 1
        if (a.timestamp < b.timestamp) return -1
        else return 0
      })[0].mob


      const coreFBI = Math.round((coreMax/coreMin)*100)-100
      const lowerFBI = Math.round((lowerMax/lowerMin)*100)-100
      const posturalFBI = Math.round((posturalMax/posturalMin)*100)-100
      const upperFBI = Math.round((upperMax/upperMin)*100)-100

      const FBI = Math.round((coreFBI+lowerFBI+posturalFBI+upperFBI) / 4)

			let firsts = [data.core[0],data.lower[0],data.postural[0],data.upper[0]]
			firsts.sort((a, b)=>{
				if (a.timestamp < b.timestamp) {
					return -1;
				}
				if (a.timestamp > b.timestamp) {
					return 1;
				}
				return 0; // a must be equal to b
      })
      
      if (!firsts || !firsts[0]) firsts = [{timestamp:new Date()}]

      const maxNumSessions = Math.max(data.core.length,data.lower.length,data.postural.length,data.upper.length)

      const nextLevel = 50 * Math.floor((maxNumSessions + 50) / 50)
	
			setFirstSession(firsts[0].timestamp.toLocaleDateString())
      setFunctionalBoneIncrease(`${FBI}%`)
      setTotalSessions(maxNumSessions)
      setNextLevelSessions(nextLevel)
    }
  }, [data])


  return (<>
    <div>
      <table className="header">
        <tbody>
          <tr>
            <td className="user name" colSpan={2}>{username}</td>
          
            <td className="label"> Average Improvement </td>
            
            <td className="value"> {functionalBoneIncrease} </td>
          </tr>

          <tr>
            <td className="label"> Number of Sessions </td>
            
            <td className="value"> {totalSessions} </td>

            <td className="label"> First Session </td>

            <td className="value"> {firstSession} </td>
          </tr>
        </tbody>
      </table>
        
      {children}
    </div>
  </>)
}

export default OverallStats