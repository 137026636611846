import html2canvas from "html2canvas";

import {API} from '../config'


export function distance(x1, y1, x2, y2) {
  return Math.sqrt((x2-x1)**2 + (y2-y1)**2)
}


/** posts to OsteoStrong server
 * @param url_fragment ~ this fragment gets prepended with the remote-host
 * @param data ~ the request body
 */
export async function post(url_fragment = '', data = {}) {
  sessionStorage.setItem('loading', true)

  data._phase = process.env.NODE_ENV
  
  let body = JSON.stringify(data)

  const token = sessionStorage.getItem('jwt_token')

  const url = API.URL + '/' + url_fragment

  
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: `${body}`, // body data type must match "Content-Type" header
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json' 
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
  });

  sessionStorage.setItem('loading', false)

  return await response.json(); // parses JSON response into native JavaScript objects
}

export async function get(url_fragment = '') {
  sessionStorage.setItem('loading', true)

  // Default options are marked with *
  const response = await fetch(API.URL+url_fragment, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    headers: {
      Accept: 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('jwt_token'),
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
  })

  sessionStorage.setItem('loading', false)

  return response.json(); // parses JSON response into native JavaScript objects
}

export async function autoLogin() {
}

export const localDate = d=>(new Date(d)).toLocaleDateString()


export const barLevels = {
  "core": {
    gt: 1.0,
    rgt: 1.5,
  },
  "lower": {
    gt: 4.2,
    rgt: 7.2,
  },
  "postural": {
    gt: 2.0,
    rgt: 2.5,
  },
  "upper": {
    gt: 2.0,
    rgt: 2.5,
  },
}

export function localeWeight(X) {
  if (navigator.language === 'en-US') return X
  else return toKilograms(X)
}

export function exerciseSessions(data, exercise) {
  let result = data.filter(el=>(el.origin===exercise))
  .sort((a, b)=>{
    if (a.timestamp < b.timestamp) {
      return -1;
    }
    if (a.timestamp > b.timestamp) {
      return 1;
    }
    return 0; // a must be equal to b
  })

  let levels = barLevels[exercise]
  let prevDate = new Date(0)
  result.forEach((el,I,A)=>{
    const mob = el.mob
    const thisn = A[I]
    const thisTimestamp = new Date(el.timestamp)

    const dateDiff = (thisTimestamp - prevDate) / 86400000  // :P converting ms to days

    let color = 'grey'
    if (mob > levels.gt) color = '#EEEEEECC'
    if (mob > levels.rgt) color = '#EEB735CC'
    // if (I === 0) color = 'grey'
    if ((dateDiff > 10) && (I > 2)) color = '#777777CC'

    thisn.color = color
    // thisn.x = el.x.toLocaleDateString()
    // thisn.y = el.mob

    prevDate = thisTimestamp
  })
  
  return result
}

export const exbregatedPosition = val=>Math.round((val/5)*10)/10


export async function command(address='', data={}) {
  const body = JSON.stringify(data)
  const url = `https://${address}:3000/cmd`

  try {
    return await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: `${body}`, // body data type must match "Content-Type" header
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json' 
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export function heightString(inches, units) {
  if (units === 'Metric') {
    let meters = inches / 39.3701
    meters = meters.toFixed(2)
    return `${meters}m`
  } else {
    let partialInches = inches % 12
    let feet = inches / 12
    feet = Math.trunc(feet)

    return `${feet}' ${partialInches}"` 
  }
}


export function peakPerformance(data, mobResult=true) {
  const peakLength = 10

  let theData = data.slice()

  theData = theData
  .sort((a, b) => {
    if (a.mob < b.mob) {
      return 1;
    }
    if (a.mob > b.mob) {
      return -1;
    }
    return 0; // a must be equal to b
  })
  .slice(0, peakLength)

  // let peak = 0
  // theData.forEach(el=>peak+=el.mob)
  // peak /= peakLength

  let avg = 0

  if (mobResult) {
    avg = theData.reduce((total, next) => total + next.mob, 0) / theData.length
  } else {
    avg = theData.reduce((total, next) => total + next.force, 0) / theData.length
  }

  return avg * 0.85
}


export async function screenCapture() {
  const screenshotTarget = document.body;
  
  const canvas = await html2canvas(screenshotTarget)

  const base64image = canvas.toDataURL("image/png")

  return base64image
}


export async function screenshot() {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const video = document.createElement("video");


  try {
    const captureStream = await navigator.mediaDevices.getDisplayMedia();

    video.srcObject = captureStream;

    context.drawImage(video, 0, 0, window.width, window.height);

    const frame = canvas.toDataURL("image/png");

    captureStream.getTracks().forEach(track => track.stop());
    
    return frame;
  } catch (err) {
    console.error("Error: " + err);
  }
};


export function toKilograms(pounds) {
  return pounds * 0.453592
}


export function weightString(pounds, units) {
  if (units === 'Metric') {
    let kilos = pounds / 2.20462
    kilos = kilos.toFixed(2)
    return `${kilos}kg`
  } else {
    const lbs = Math.trunc(pounds)

    return `${lbs}lb`
  }
}