import React, {useEffect, useRef, useState} from 'react'
import {calculateAll} from '../../lib/posture/analysis'
import {drawSkeleton} from '../../lib/posture/drawer'

import Joint from "./Joint";
import Markers from './Markers';
import Skeleton from "./Skeleton";

import { parseServerData } from "./postureData";

import muscleMap from '../../img/muscle-diagram.png'


export default function Posture({
  breakAfter=true,
  data,
}) {
  const canvasRef = useRef()

  const [height, setHeight] = useState(480)
  const [pics, setPics] = useState()
  const [width, setWidth] = useState(640)

  const [drawBox, setDrawBox] = useState({
    left: 100,
    right: width-100,
    top: 50,
    bottom: height-100,
    front: 0,
    back: 1,
  })
  const [joints, setJoints] = useState()
  const [jointPoints, setJointPoints] = useState()
  const [score, setScore] = useState(-7)
  
  const dataUrl = base64=>`data:image/jpeg;base64,${base64}`


  useEffect(() => {
    setDrawBox({
      left: 100.0,
      right: width-100.0,
      top: 50.0,
      bottom: height-100.0,
      front: 0.0,
      back: 1.0,
    })
  }, [height,width])


  useEffect(() => {
    if (data && Object.keys(data)?.length) {
      const pic = document.querySelector('img.posture-img')
      
      const newPoints = parseServerData(data, pic)
      
      setJointPoints(newPoints.data)
      
      if (data?.pics) setPics(data.pics)
    }
  }, [data])


  useEffect(() => {
    if (canvasRef?.current && jointPoints) {
      const res = calculateAll(jointPoints, canvasRef.current)
      
      drawSkeleton({
        canvas: canvasRef.current,
        canvasCtx: canvasRef.current.getContext('2d'),
        data: res,
      })
    }
  }, [canvasRef, jointPoints])


  return (<>
  <div className={`mini chart outside-container ${breakAfter? 'page-break': ''}`}>
    <div className={`
      mini chart inside-container
      ${(jointPoints)? 'posture': ''}
    `}>
      {(pics)? <>
        <div className="mini chart title">
          <div>
            <span className="mini chart name">Posture</span>
            <span className="mini chart performance separator">Assessment</span>
          </div>
        </div>

        <div className="mini chart content">
          {pics? pics.map((pic,I)=><img
            key={I}
            src={dataUrl(pic)}
            alt="posture"
            className='posture-img'
          />)
        :<></>}

{/* 
          <canvas
            className="posture-canvas"
            ref={canvasRef}
          />
 */}
          {/* {JSON.stringify(jointPoints)} */}

          {/* 
          {joints.map((key,I)=><Joint key={I} point={jointPoints[key]} />)}
          
          
          <svg 
            viewBox={`0 0 ${width} ${height}`}
            style={{backgroundColor: '#FFF0'}}
            >

            <Skeleton />

            <Markers />
          </svg>

          <img src={muscleMap} alt="muscle map" /> */}
        </div>
      </>
      :<>No posture data</>}
    </div>
  </div>
  </> )
}