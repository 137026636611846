import {useEffect, useState} from 'react'
import MachineSelector from './MachineSelector'
import MiniChart from "./MiniChart";
import ModeButton from "../ModeButton"


export default function Spectrum({
  upperLevels,
  lowerLevels,
  coreLevels,
  posturalLevels,
  upperData,
  lowerData,
  coreData,
  posturalData,
  stats,
}) {
  const [data, setData] = useState(coreData)
  const [levels, setLevels] = useState(coreLevels)
  const [machine, setMachine] = useState('')
  const [mode, setMode] = useState('mob')
  const [title, setTitle] = useState('')


  useEffect(() => {
    switch (machine) {
      case 'core':
        setData(coreData)
        setLevels(coreLevels)
        setTitle('CORE GT')
        break
      
      case 'lower':
        setData(lowerData)
        setLevels(lowerLevels)
        setTitle('LOWER GT')
        break
      
      case 'postural':
        setData(posturalData)
        setLevels(posturalLevels)
        setTitle('POSTURAL GT')
        break
      
      case 'upper':
        setData(upperData)
        setLevels(upperLevels)
        setTitle('UPPER GT')
        break
      
      default:
        setData(coreData)
        setLevels(coreLevels)
        setTitle('CORE GT')
        break
    }
  }, [machine])


  return <>
    <ModeButton mode={mode} setMode={setMode} />
    
    <MachineSelector
      machine={machine}
      setMachine={setMachine}
    />
    
    <MiniChart
      levels={levels} 
      data={data}
      mode={mode}
      stats={stats}
      title={title}
    />
  </>
}