import React,{useEffect, useRef, useState} from 'react'
import CanvasJSReact from '../lib/canvasjs.react'
import { peakPerformance } from "../lib/helpers";

var CanvasJSChart = CanvasJSReact.CanvasJSChart


function SessionGraph({data=[],levels,origin="core", startDate,endDate}) {
  const [peakPerf, setPeakPerf] = useState(0)
  const [sessions, setSessions] = useState([])

  const chart = useRef(null)


  function toolTipContent(e) {
    var total = 0;
    var str2, str3;
    
    for (var i = 0; i < e.entries.length; i++){
      total = e.entries[i].dataPoint.y + total;
    }
    str2 = `<span style="color:black"><strong>${(e.entries[0].dataPoint.x).toLocaleString()}</strong></span><br/>`
    
    total = Math.round(total * 100) / 100;
    
    str3 = `<span style="color: black">MOB:&nbsp;</span><strong>${total}</strong><br/>`
    
    return str2.concat(str3)
  }

  const options = function(){
    if (sessions.length){
      const barGap = 7
      const barWidth = Math.round(chart.current.width / data.length) - barGap
      
      const stripLineThickness = levels.rgt / 200
      
      return {
        axisX: {
          labelAngle: -50,
          labelFontColor: "#EEB735",
          labelFontSize: 15,    
        },
        axisY: {
          interlacedColor: "rgba(238,153,83,0.1)",
          gridColor: "grey",
          includeZero: true,
          labelFontColor: "grey",
          labelFontSize: 17,
          stripLines: [{
            startValue: peakPerf,
            endValue: peakPerf + stripLineThickness,
            color: '#FF0',
            label: 'Peak Performance',
            labelAlign: 'far',
            labelBackgroundColor: '#0007',
            labelFontColor: '#FF9',
            labelPlacement: 'inside',
            showOnTop: true,
          }],
        },
        // backgroundColor: 'black',
        dataPointWidth: barWidth,
        data: [{
          color: 'black',
          dataPoints: sessions,
          lineThickness: 1,
          markerType: "none",
          type: "spline",
        }],
        exportEnabled: true,
        includeZero: false,
        title: {
          // text: `${origin} Sessions`.capitalize()
          text: `Sessions`
        },
        theme: 'light1',
        toolTip: {
          contentFormatter: toolTipContent,
        }
      }
    } else {
      return {}
    }
  }


  useEffect(() => {
    let end = new Date(endDate)
    let start = new Date(startDate)
    
    setPeakPerf(peakPerformance(data))
    
    let temp = data
    .filter(el=>((el.x >= start) && (el.x <= end)))
    
    setSessions(temp)
  }, [data, endDate, startDate])


  return (
    <div className="mini session chart">
      <CanvasJSChart onRef={ref=>chart.current=ref} className="base chart" options={options()} />
    </div>
  )
}

export default SessionGraph