import React, { useEffect, useState } from 'react'

import { post } from './lib/helpers'


function Locations({ user }) {
  const [buttonClass, setButtonClass] = useState('btn btn-info control')
  const [, setLocation] = useState({})


  useEffect(() => {
    post('member/location', { location_id: user.location_id })
      .then(data => {
        if (data.address) data.address = JSON.parse(data.address)
        if (data.contact) data.contact = JSON.parse(data.contact)

        setLocation(data)
      })
      .catch(err => console.error(err))

    const checkedIn = user.on_premises?.data?.[0]
    if (checkedIn) setButtonClass('btn btn-success control')
    else setButtonClass('btn btn-warning control')
  }, [user])

  function checkIn(event) {
    post('member/check_in', {member_id:user.id})
    .then(result=>{
      if (result.checkIn) user.on_premises = true
    })
    .catch(err=>console.error(err))
  }

  return (
    <section className="row justify-content-center col-auto">
      <button className={buttonClass} onClick={checkIn}>Check In</button>
    </section>
  )
}

export default Locations