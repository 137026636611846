const descriptors = {
  nose: "nose",
  left_eye_inner: "left_eye_inner",
  left_eye: "left_eye",
  left_eye_outer: "left_eye_outer",
  right_eye_inner: "right_eye_inner",
  right_eye: "right_eye",
  right_eye_outer: "right_eye_outer",
  left_ear: "left_ear",
  right_ear: "right_ear",
  mouth_left: "mouth_left",
  mouth_right: "mouth_right",
  left_shoulder: "left_shoulder",
  right_shoulder: "right_shoulder",
  left_elbow: "left_elbow",
  right_elbow: "right_elbow",
  left_wrist: "left_wrist",
  right_wrist: "right_wrist",
  left_pinky: "left_pinky",
  right_pinky: "right_pinky",
  left_index: "left_index",
  right_index: "right_index",
  left_thumb: "left_thumb",
  right_thumb: "right_thumb",
  left_hip: "left_hip",
  right_hip: "right_hip",
  left_knee: "left_knee",
  right_knee: "right_knee",
  left_ankle: "left_ankle",
  right_ankle: "right_ankle",
  left_heel: "left_heel",
  right_heel: "right_heel",
  left_foot_index: "left_foot_index",
  right_foot_index: "right_foot_index",
}

const landmarkNames = [
  "nose",
  "left_eye_inner",
  "left_eye",
  "left_eye_outer",
  "right_eye_inner",
  "right_eye",
  "right_eye_outer",
  "left_ear",
  "right_ear",
  "mouth_left",
  "mouth_right",
  "left_shoulder",
  "right_shoulder",
  "left_elbow",
  "right_elbow",
  "left_wrist",
  "right_wrist",
  "left_pinky",
  "right_pinky",
  "left_index",
  "right_index",
  "left_thumb",
  "right_thumb",
  "left_hip",
  "right_hip",
  "left_knee",
  "right_knee",
  "left_ankle",
  "right_ankle",
  "left_heel",
  "right_heel",
  "left_foot_index",
  "right_foot_index",
]

let frame = {}
let focalLength = 500
let joint = {}
let meta = {}
let point = {}


function calculateFrame() {
  for (let key in point) {
    if (key !== 'midPelvis') {
      frame[key] = distance(point[key], point.midPelvis)
    }
  }

  return frame
}


function calculateJoints() {
  joint = {}

  joint.leftShoulder = jointAngle(point.leftElbow, point.leftShoulder, point.leftHip)
  joint.rightShoulder = jointAngle(point.rightElbow, point.rightShoulder, point.rightHip)
  
  joint.leftElbow = jointAngle(point.leftShoulder, point.leftElbow, point.leftWrist)
  joint.rightElbow = jointAngle(point.rightShoulder, point.rightElbow, point.rightWrist)
  
  joint.leftWrist = jointAngle(point.leftElbow, point.leftWrist, point.leftIndex)
  joint.rightWrist = jointAngle(point.rightElbow, point.rightWrist, point.rightIndex)
  
  joint.leftHip = jointAngle(point.leftShoulder, point.leftHip, point.leftKnee)
  joint.rightHip = jointAngle(point.rightShoulder, point.rightHip, point.rightKnee)
  
  joint.leftKnee = jointAngle(point.leftHip, point.leftKnee, point.leftAnkle)
  joint.rightKnee = jointAngle(point.rightHip, point.rightKnee, point.rightAnkle)
  
  joint.leftTorso = jointAngle(point.leftHip, point.leftShoulder, point.rightShoulder)
  joint.rightTorso = jointAngle(point.rightHip, point.rightShoulder, point.leftShoulder)

  joint.leftWaist = jointAngle(point.leftKnee, point.leftHip, point.rightHip)
  joint.rightWaist = jointAngle(point.rightKnee, point.rightHip, point.leftHip)

  joint.leftAnkle = jointAngle(point.leftKnee, point.leftAnkle, point.leftFootIndex)
  joint.rightAnkle = jointAngle(point.rightKnee, point.rightAnkle, point.rightFootIndex)

  joint.spineBottom = jointAngle(point.rightHip, point.midPelvis, point.clavicle)
  joint.spineTop = jointAngle(point.leftShoulder, point.clavicle, point.midPelvis)

  joint.neck = (
    jointAngle(point.rightShoulder, point.clavicle, point.nose)
    + jointAngle(point.leftShoulder, point.clavicle, point.nose)
  ) / 2

  return joint
}


function calculateLengths() {
  const result = {}

  result.height = 1.1 * distance(point.leftEye, point.leftAnkle)
  result.shoulderWidth = distance(point.leftShoulder, point.rightShoulder)
  result.hipWidth = distance(point.leftHip, point.rightHip)
  result.torsoHeight = distance(point.clavicle, point.midPelvis)
  
  result.rightAbdominalHeight = distance(point.leftShoulder, point.leftHip)
  result.leftAbdominalHeight = distance(point.rightShoulder, point.rightHip)
  
  result.leftHumerus = distance(point.leftShoulder, point.leftElbow)
  result.rightHumerus = distance(point.rightShoulder, point.rightElbow)
  
  result.leftLowerArm = distance(point.leftElbow, point.leftWrist)
  result.rightLowerArm = distance(point.rightElbow, point.rightWrist)
  
  result.leftFemur = distance(point.leftHip, point.leftKnee)
  result.rightFemur = distance(point.rightHip, point.rightKnee)
  
  result.leftLowerLeg = distance(point.leftKnee, point.leftAnkle)
  result.rightLowerLeg = distance(point.rightKnee, point.rightAnkle)

  result.headWidth = point[point.rightEar]?.x - point[point.leftEar]?.x

  return result
}


function calculateMeta() {
  const result = {}
  
  meta.torsoLevel = intersectionAngle(
    point.leftShoulder,
    point.rightShoulder,
    point.leftHip,
    point.rightHip,
  )
  
  meta.torsoPlumb = intersectionAngle(
    point.leftShoulder,
    point.leftHip,
    point.rightShoulder,
    point.rightHip,
  )
  
  meta.backAngle = (joint.leftHip + joint.rightHip) / 2
  
  if ((joint.shoulderHipLeft < 85) && (joint.shoulderHipRight < 85)) {
    result.bodyType = 'ectomorph'
  } else if ((joint.shoulderHipLeft > 90) && (joint.shoulderHipRight > 90)) {
    result.bodyType = 'mesomorph'
  } else {
    result.bodyType = 'endomorph'
  }
  
  return result
}


function distance(A, B) {
  try {
    if (!(A && B)) return
    
    return Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2) + Math.pow(B.z - A.z, 2))
  } catch (error) {
    console.error('distance points fail', A, B, error)
    
    return 0
  }
}


function intersectionAngle(A, B, C, D) {
  try {
    const slopeAB = slope(A, B)
    const slopeCD = slope(C, D)
    
    let result = Math.atan((slopeAB - slopeCD) / (1 + slopeAB * slopeCD))

    result = (result * 180) / Math.PI

    return result
  } catch (error) {
    console.error('intersection-angle points fail', A, B, C, D, error)

    return 0
  }
}


function jointAngle(pointA, pointB, pointC) {
  try {
    const ABdist = distance(pointB, pointA)
    const BCdist = distance(pointB, pointC)
    const ACdist = distance(pointC, pointA)
    
    let result = Math.acos((BCdist*BCdist+ABdist*ABdist-ACdist*ACdist)/(2*BCdist*ABdist));
    
    result = (result * 180) / Math.PI
    
    return result
  } catch (error) {
    console.error('joint-angle fail', pointA, pointB, pointC)
    
    return 0
  }
}


function loadPoints(landmarks, canvas, flipX=false) {
  for (let I in landmarks) {
    const P = landmarks[I]
    
    let resultX = P.x * canvas.width
    let resultY = P.y * canvas.height
    let resultZ = P.z
    
    if (flipX) {
      resultX = canvas.width - resultX
    }
    
    let result = project(resultX, resultY, resultZ)
    
    point[I] = {
      ...result,
      inverseX: canvas.width - resultX,
    }
  }
  
  point.clavicle = midpoint(point.leftShoulder, point.rightShoulder)
  point.midPelvis = midpoint(point.leftHip, point.rightHip)
  
  return point
}


function midpoint(A, B) {
  try {
    return {
      x: (A.x + B.x) / 2,
      y: (A.y + B.y) / 2,
      z: (A.z + B.z) / 2,
    }
  } catch (err) {
    console.error(err)
  }
}


function project(x, y, z) {
  let scale = focalLength / (z + focalLength)
  
  return { x: x * scale, y: y * scale, z: z * scale }
}


function slope(A, B) {
  try {
    return (B.y - A.y) / (B.x - A.x)
  } catch (error) {
    console.error('slope points fail', A, B, error)
  }
}


function calculateAll(landmarks, canvas, flipX=false) {
  const P = loadPoints(landmarks, canvas, flipX)
  
  const L = calculateLengths()
  
  const J = calculateJoints()
  
  const M = calculateMeta()
  
  const F = calculateFrame()
  
  const result = {
    frame: F,
    joint: J,
    length: L,
    meta: M,
    point: P,
  }
  
  return result
}


export {
  calculateAll,
}