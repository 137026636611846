let focalLength = 500


function drawArc({
  clockwise = false,
  canvasCtx,
  pointA,
  pointB,
  radius=10,
}) {
  canvasCtx.beginPath();

  // The angle to start the arc (from the horizontal line)
  let startAngle = Math.atan2(pointA.y - pointB.y, pointA.x - pointB.x);

  // Calculate the endpoint of the arc
  let endX = pointA.x + radius * Math.cos(startAngle);
  let endY = pointA.y + radius * Math.sin(startAngle);

  // Calculate the center of the circle
  let cx = pointA.x + radius * Math.cos(startAngle + Math.PI / 2);
  let cy = pointA.y + radius * Math.sin(startAngle + Math.PI / 2);

  canvasCtx.moveTo(pointA.x, pointA.y); // Move to the start point
  canvasCtx.bezierCurveTo(pointA.x, pointA.y, pointB.x, pointB.y, pointB.x, pointB.y)
  // canvasCtx.arc(cx, cy, radius, startAngle, startAngle + Math.PI, clockwise);
  // canvasCtx.lineTo(pointB.x, pointB.y); // Line to the end point
  
  canvasCtx.stroke();
}


function drawCircle({
  centerPoint,
  pointA,
  pointB,
  canvasCtx,
}) {
  // Calculate the radius using one of the edge points (pointA.x, pointA.y)
  const radius1 = Math.sqrt((pointA.x - centerPoint.x) ** 2 + (pointA.y - centerPoint.y) ** 2);
  const radius2 = Math.sqrt((pointB.x - centerPoint.x) ** 2 + (pointB.y - centerPoint.y) ** 2);

  const radius = (radius1 + radius2) / 2
  
  canvasCtx.beginPath();
  canvasCtx.arc(centerPoint.x, centerPoint.y, radius, 0, 2 * Math.PI);
  canvasCtx.stroke();
}


function drawFrame({
  canvas,
  canvasCtx,
  data,
  flipHorizontal=false,
}) {
  canvasCtx.save();
  canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  
  for (let key in data.point) {
    const P = data.point[key]
    
    if (P) {
      drawLine({
        canvasCtx: canvasCtx,
        color: 'green',
        flipHorizontal: flipHorizontal,
        pointA: P,
        pointB: data.point.midPelvis,
      })
    } else {
      console.error('BAD FRAME POINT', key, P)
    }
  }
  
  canvasCtx.restore()
}


function drawJoint({
  flipHorizontal=false,
  pointNameB,
  data,
  canvasCtx,
}) {
  const paddingX = 3
  const paddingY = 2
  const thisJoint = data.joint[pointNameB]
  const thisPoint = data.point[pointNameB]
  const text = Math.round(thisJoint) + '°'
  
  canvasCtx.font = '12px newRoboto'
  
  const textWidth = canvasCtx.measureText(text).width; // Get the text width
  const textHeight = parseInt(canvasCtx.font); // derive height from the font size
  
  canvasCtx.beginPath()
  
  let ellipseX = thisPoint.x
  let ellipseY = thisPoint.y
  let ellipseRX = textWidth / 2
  let ellipseRY = textHeight / 2
  let textX = thisPoint.x
  let textY = thisPoint.y
  
  if (flipHorizontal) {
    ellipseX = thisPoint.inverseX
    textX = thisPoint.inverseX
  }
  
  textX -= textWidth / 2
  textY += (textHeight / 2) - (paddingY / 2)
  ellipseRX += paddingX
  ellipseRY += paddingY
  
  canvasCtx.fillStyle = '#0007'
  canvasCtx.ellipse(ellipseX, ellipseY, ellipseRX, ellipseRY, 0, 0, Math.PI * 2)
  canvasCtx.fill()
  
  canvasCtx.fillStyle = 'red'
  canvasCtx.fillText(text, textX, textY)
}


function drawJoints({
  canvas,
  canvasCtx,
  flipHorizontal=false,
  data,
  prefill=true,
}) {
  if (prefill) {
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height)
  }
  
  drawJoint({
    flipHorizontal: flipHorizontal,
    pointNameB: 'leftElbow',
    data: data,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  
  drawJoint({
    flipHorizontal: flipHorizontal,
    pointNameB: 'rightElbow',
    data: data,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  
  drawJoint({
    flipHorizontal: flipHorizontal,
    pointNameB: 'leftShoulder',
    data: data,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  
  drawJoint({
    flipHorizontal: flipHorizontal,
    pointNameB: 'rightShoulder',
    data: data,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  
  drawJoint({
    flipHorizontal: flipHorizontal,
    pointNameB: 'leftKnee',
    data: data,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  
  drawJoint({
    flipHorizontal: flipHorizontal,
    pointNameB: 'rightKnee',
    data: data,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
}


function drawLine({
  pointA,
  pointB,
  canvasCtx,
  color='blue',
  flipHorizontal=false,
}) {
  try {
    // let avgZ = (pointA.z + pointB.z) / 2
    
    // let baseWidth = 9
    // let scalingFactor = 1 / (avgZ + focalLength)
    
    canvasCtx.beginPath()
    
    canvasCtx.lineWidth = 1;//baseWidth * scalingFactor
    canvasCtx.strokeStyle = color
    
    if (flipHorizontal) {
      canvasCtx.moveTo(pointA.inverseX, pointA.y)
      canvasCtx.lineTo(pointB.inverseX, pointB.y)
    } else {
      canvasCtx.moveTo(pointA.x, pointA.y)
      canvasCtx.lineTo(pointB.x, pointB.y)
    }

    canvasCtx.stroke()
  } catch (error) {
    console.error('LINE ERR', error, pointA, pointB)
  }
}


function drawSkeleton({
  canvas,
  canvasCtx,
  data,
}) {
  canvasCtx.save();
  canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

  // Head
  drawLine({
    pointA: data.point.leftEar,
    pointB: data.point.leftEye,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  drawLine({
    pointA: data.point.leftEye,
    pointB: data.point.nose,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  drawLine({
    pointA: data.point.nose,
    pointB: data.point.rightEye,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })
  drawLine({
    pointA: data.point.rightEye,
    pointB: data.point.rightEar,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })


  // Core
  drawLine({
    pointA: data.point.leftShoulder,
    pointB: data.point.rightShoulder,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.leftShoulder,
    pointB: data.point.leftHip,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.rightShoulder,
    pointB: data.point.rightHip,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.leftHip,
    pointB: data.point.rightHip,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.clavicle,
    pointB: data.point.midPelvis,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })


  // Arms
  drawLine({
    pointA: data.point.leftWrist,
    pointB: data.point.leftElbow,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.leftElbow,
    pointB: data.point.leftShoulder,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.rightWrist,
    pointB: data.point.rightElbow,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.rightElbow,
    pointB: data.point.rightShoulder,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  // Legs
  drawLine({
    pointA: data.point.leftAnkle,
    pointB: data.point.leftKnee,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.leftKnee,
    pointB: data.point.leftHip,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.rightAnkle,
    pointB: data.point.rightKnee,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })

  drawLine({
    pointA: data.point.rightKnee,
    pointB: data.point.rightHip,
    canvas: canvas,
    canvasCtx: canvasCtx,
  })


  canvasCtx.restore()
}


function setFocalLength(val) {
  focalLength = val
}


export {
  drawFrame,
  drawJoint,
  drawJoints,
  drawLine,
  drawSkeleton,
  setFocalLength
}