import React,{useEffect,useState} from 'react'

function OverallStats({data}) {
  const [functionalBoneIncrease, setFunctionalBoneIncrease] = useState(0)
  const [totalSessions, setTotalSessions] = useState(0)

  useEffect(() => {
    if (data && data.lower) {
      // Functional Bone Increase
      // const coreMax = Math.min.apply(null, data.core)
      const coreMax = data.core.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const coreMin = data.core.reduce((a,el)=>(!a || el.mob<a) ? el.mob : a, 0)
      const lowerMax = data.lower.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const lowerMin = data.lower.reduce((a,el)=>(!a || el.mob<a) ? el.mob : a, 0)
      const posturalMax = data.postural.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const posturalMin = data.postural.reduce((a,el)=>(!a || el.mob<a) ? el.mob : a, 0)
      const upperMax = data.upper.reduce((a,el)=>(!a || el.mob>a) ? el.mob : a, 0)
      const upperMin = data.upper.reduce((a,el)=>(!a || el.mob<a) ? el.mob : a, 0)

      const coreFBI = Math.round((coreMax/coreMin)*100)-100
      const lowerFBI = Math.round((lowerMax/lowerMin)*100)-100
      const posturalFBI = Math.round((posturalMax/posturalMin)*100)-100
      const upperFBI = Math.round((upperMax/upperMin)*100)-100

      const FBI = (coreFBI+lowerFBI+posturalFBI+upperFBI) / 4

      setFunctionalBoneIncrease(`${FBI}%`)
      setTotalSessions(data.lower.length)
    }
  }, [data])

  function DataRow({name,value}) {
    return <tr>
      <td className="label">{name}</td>
      <td className="value">{value}</td>
    </tr>
  }

  return (
    <table className="stats">
      <tbody>
        <DataRow name="Functional Bone Increase" value={functionalBoneIncrease} />
        <DataRow name="Total Sessions" value={totalSessions} />
      </tbody>
    </table>
  )
}

export default OverallStats