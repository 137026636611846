import React, {useEffect, useState} from 'react'
import CanvasJSReact from '../../lib/canvasjs.react'

const CanvasJSChart = CanvasJSReact.CanvasJSChart
const CanvasJS = CanvasJSReact.CanvasJS


export default function Pain({
  breakAfter=true,
  data,
}) {
  const [chart, setChart] = useState({})
  const [options, setOptions] = useState({})
  const [score, setScore] = useState(-7)


  function buildOptions(data) {
    if (!data) return {}

    const curves = []
    const template = {
      color: 'darkgray',
      connectNullData: true,
      // dataPoints: chartData,
      // name: 'Grip',
      mouseout: unhighlightSeries,
      mouseover: highlightSeries,
      nullDataLineDashType: "dash",
      type: "spline",
      xValueType: "datetime",
      xValueFormatString: "YY MMM DD hh:mm TT",
      yValueFormatString: "#0.0",
    }


    const keys = Object.keys(data)

    for (let key of keys) {
      const curveOptions = {
        ...template,
        dataPoints: data[key],
        name: key,
      }
      
      curves.push(curveOptions)
    }


    let result = {
      animationEnabled: true,
      axisX: {
        labelFormatter: function (e) {
          return CanvasJS.formatDate( e.value, "MMM DD");
        },
        labelFontSize: 20,
        // stripLines: stripColumns,
      },
      
      axisY: {
        includeZero: true,
        labelFontSize: 20,
        // maximum: 10,
        // stripLines: stripLines,
      },
      
      data: curves,
      
      toolTip: {
        contentFormatter: toolTip,
        shared: true,
      },
    }


    return result
  }


  function highlightSeries(e) {
    e.dataSeries.lineThickness = 7
    chart.current.render();
  }


  function unhighlightSeries(e) {
    e.dataSeries.lineThickness = 1
    chart.current.render();
  }


  function toggleDataSeries(e){
    if (
      typeof(e.dataSeries.visible) === "undefined"
      || e.dataSeries.visible
    ) {
      e.dataSeries.visible = false;
    } else{
      e.dataSeries.visible = true;
    }
    
    chart.current.render();
  }


	const toolTip = E=>{
    let data = E.entries[0].dataPoint.dat
    let when = (E.entries[0].dataPoint.x)
    when = new Date(when)
    if (when?.getDate()) when = when.toLocaleDateString()

    let result = `<ul>
      <li>${when}</li>
      ${Object.keys(data).map(key=>{
        let el = data[key]

        if (!
          key.includes('id')
          && !key.includes('created')
          && (key !== 'timestamp')
          && (el > 0)
        ) {
          let res = `<li>${key}: ${el}</li>`
          return res
        }
      }).join('')}
    </ul>`
    
    return result
	}


  useEffect(() => {
    if (data?.length) {
      const newCurve = {pain: []}
      
      for (let snapshot of data) {
        let totalPain = 0
        let timestamp = snapshot.timestamp
        
        for (let key in snapshot) {
          if (!
            key.includes('id')
            && (key !== 'created')
            && (key !== 'timestamp')
          ) {
            totalPain += +snapshot[key]
          }
        }
        
        newCurve.pain.push({
          x: new Date(timestamp),
          y: totalPain,
          dat: snapshot,
        })
      }
      
      const newOptions = buildOptions(newCurve)
      
      setOptions(newOptions)
    }
  }, [data])


  return (<div className={`mini chart outside-container posture ${breakAfter? 'page-break': ''}`}>
    <div className="mini chart inside-container">
      {data? 
        <>
          <div className="mini chart title">
            <div>
              <span className="mini chart name">Pain & Mobility</span>
              <span className="mini chart performance separator">Assessment</span>
            </div>
            
            <div>
              <span className="mini chart name">Score</span>
              <span className="mini chart performance separator">{score}#</span>
            </div>
          </div>
          
          <CanvasJSChart
            onRef={ref=>chart.current=ref}
            className="base chart"
            options={options}
          />
        </>
      :
        <>No pain/mobility data</>
      }
    </div>
  </div>)
}
