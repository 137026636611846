import React, {useEffect, useRef} from 'react'

export default function ModeButton({mode='mob', setMode}) {
  const mobButton = useRef(null)
  const forceButton = useRef(null)


  function setModeMOB(event) {
    setHighlight('mob')
    setMode('mob')
  }
  
  
  function setModeForce(event) {
    setHighlight('force')
    setMode('force')
  }
  

  function setHighlight(thisn) {
    if (mobButton && forceButton) {
      if (thisn === 'mob') {
        forceButton.current.classList.remove('highlight')
        mobButton.current.classList.add('highlight')
      } 
      
      if (thisn === 'force') {
        forceButton.current.classList.add('highlight')
        mobButton.current.classList.remove('highlight')      
      }
    }
  }


  useEffect(() => {
    setHighlight()
  }, [])


  return (<>
    <div className="mode button row">
      <div>
        <button
          className="mode toggle button highlight"
          onClick={setModeMOB}
          ref={mobButton} 
          type="button"
        >Multiples of Bodyweight</button>

        <button 
          className="mode toggle button"
          onClick={setModeForce}
          ref={forceButton} 
          type="button"
        >Force Loaded</button>
      </div>
    </div>
  </>)
}