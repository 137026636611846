let maxX, maxY, maxZ, minX, minY, minZ
const landmarks = [
  "nose",
  "leftEye",
  "rightEye",
  "leftShoulder",
  "rightShoulder",
  "leftElbow",
  "rightElbow",
  "leftWrist",
  "rightWrist",
  "leftHip",
  "rightHip",
  "leftKnee",
  "rightKnee",
  "leftAnkle",
  "rightAnkle",
]

let joints = {}
let lengths = {}
let meta = {}
let points = {}


function calculateJoints() {
  joints.leftShoulderHip = jointAngle('rightShoulder', 'leftShoulder', 'leftHip')
  joints.rightShoulderHip = jointAngle('leftShoulder', 'rightShoulder', 'rightHip')

  joints.leftShoulderElbow = jointAngle('rightShoulder', 'leftShoulder', 'leftElbow')
  joints.rightShoulderElbow = jointAngle('leftShoulder', 'rightShoulder', 'rightElbow')
  
  joints.leftHipKnee = jointAngle('rightHip', 'leftHip', 'leftKnee')
  joints.rightHipKnee = jointAngle('leftHip', 'rightHip', 'rightKnee')

  joints.leftElbow = jointAngle('leftShoulder', 'leftElbow', 'leftWrist')
  joints.rightElbow = jointAngle('rightShoulder', 'rightElbow', 'rightWrist')

  joints.leftHip = jointAngle('rightHip', 'leftHip', 'leftKnee')
  joints.rightHip = jointAngle('leftHip', 'rightHip', 'rightKnee')

  joints.leftKnee = jointAngle('leftHip', 'leftKnee', 'leftAnkle')
  joints.rightKnee = jointAngle('rightHip', 'rightKnee', 'rightAnkle')

  joints.leftTorso = jointAngle('leftShoulder', 'leftHip', 'leftKnee')
  joints.rightTorso = jointAngle('rightShoulder', 'rightHip', 'rightKnee')

  joints.shoulderLevel = (joints.leftShoulderHip + joints.rightShoulderHip) / 2.0
  
  joints.hipLevel = (joints.leftHipKnee + joints.rightHipKnee) / 2.0
  
  joints.torsoLevel = (joints.leftTorso + joints.rightTorso) / 2.0
}


function calculateLengths() {
  lengths.height = 1.1 * distance('leftEye', 'leftAnkle')
  lengths.shoulderWidth = distance('leftShoulder', 'rightShoulder')
  lengths.hipWidth = distance('leftHip', 'rightHip')
  
  lengths.rightAbdominalHeight = distance('leftShoulder', 'leftHip')
  lengths.leftAbdominalHeight = distance('rightShoulder', 'rightHip')
  
  lengths.leftHumerus = distance('leftShoulder', 'leftElbow')
  lengths.rightHumerus = distance('rightShoulder', 'rightElbow')
  
  lengths.leftLowerArm = distance('leftElbow', 'leftWrist')
  lengths.rightLowerArm = distance('rightElbow', 'rightWrist')
  
  lengths.leftFemur = distance('leftHip', 'leftKnee')
  lengths.rightFemur = distance('rightHip', 'rightKnee')
  
  lengths.leftLowerLeg = distance('leftKnee', 'leftAnkle')
  lengths.rightLowerLeg = distance('rightKnee', 'rightAnkle')

  lengths.headWidth = points['rightEar']?.x - points['leftEar']?.x
}


function calculateMeta() {
  try {
    if ((joints.leftShoulderHip < 85) && (joints.rightShoulderHip < 85)) {
      meta.bodyType = 'ectomorph'
    } else if ((joints.leftShoulderHip > 90) && (joints.rightShoulderHip > 90)) {
      meta.bodyType = 'mesomorph'
    } else {
      meta.bodyType = 'endomorph'
    }
    
    // meta.handsOverhead = (points.leftWrist.y < points.nose.y) && (points.rightWrist.y < points.nose.y)
    // meta.handsClose = (Math.abs(points.leftWrist.x - points.rightWrist.x) < 5) && (Math.abs(points.leftWrist.y - points.rightWrist.y) < 5)
  } catch (error) {
    console.error('POSTURE META CALC ERR', error)
  }
}


function distance(A, B) {
  if (!Object.keys(points).length) return
  
  if (!A || !B) return 0
  
  try {
    const P1 = points[A]
    const P2 = points[B]
    
    return Math.sqrt(Math.pow(P2.x - P1.x, 2) + Math.pow(P2.y - P1.y, 2))
  } catch (error) {
    console.error('distance points fail', A, B, error)
    
    return 0
  }
}


function jointAngle(A, B, C) {
  if (!Object.keys(points).length) return

  try {
    const ABdist = distance(B, A)
    const BCdist = distance(B, C)
    const ACdist = distance(C, A)

    
    let result = Math.acos((BCdist*BCdist+ABdist*ABdist-ACdist*ACdist)/(2*BCdist*ABdist));
    
    result = (result * 180) / Math.PI
    

    return Math.round(result)
  } catch (error) {
    console.error('joint-angle fail', A, B, C)
    
    return 0
  }
}


/**
 * 
 * @param {[]} data
 * @param {{x:<Integer>, y:<Integer>}} viewBox
 * @returns 
 */
function parseServerData(data, viewBox) {
  const keys = Object.keys(data)
  

  for (let key of keys) {
    let thisn


    try {
      thisn = JSON.parse(data[key])
    } catch (error) {
      ;
    }


    if (thisn?.x) {
      if (!maxX) maxX = thisn.x + 0.0001
      if (!minX) minX = thisn.x - 0.0001
      if (!maxY) maxY = thisn.y + 0.0001
      if (!minY) minY = thisn.y - 0.0001
      if (!maxZ) maxZ = thisn.z + 0.0001
      if (!minZ) minZ = thisn.z - 0.0001


      if (thisn.x < minX) minX = thisn.x
      if (thisn.x > maxX) maxX = thisn.x
      if (thisn.y < minY) minY = thisn.y
      if (thisn.y > maxY) maxY = thisn.y
      if (thisn.z < minZ) minZ = thisn.z
      if (thisn.z > maxZ) maxZ = thisn.z
    }
  }


  for (let key of keys) {
    let thisn


    try {
      thisn = JSON.parse(data[key])
    } catch (error) {
      ;
    }


    if (thisn?.x) {
      if (viewBox) thisn = scale(thisn, viewBox)

      points[key] = thisn
    }
  }
  
  calculateJoints()
  calculateLengths()
  calculateMeta()
  
  return points
}


function scale(point, size) {
  const xRatio = (point.x - minX) / (maxX - minX)
  const yRatio = (point.y - minY) / (maxY - minY)
  const zRatio = (point.z - minZ) / (maxZ - minZ)

  const width = size?.width
  const height = size?.height
  const depth = size?.depth

  let result = {
    x: (xRatio * width),
    y: (yRatio * height),
    z: (zRatio * depth),
  }


  return result
}


export {
  parseServerData,
}