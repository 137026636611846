import React,{useEffect,useRef,useState} from 'react'
import CanvasJSReact from '../lib/canvasjs.react'
import {peakPerformance} from "../lib/helpers";


var CanvasJSChart = CanvasJSReact.CanvasJSChart
var CanvasJS = CanvasJSReact.CanvasJS


function MiniChart({
	breakAfter=false,
	data,
	endDate,
	levels,
	mode='mob',
	showMinimumTrigger=false,
	startDate,
	stats,
	title='GT'
}) {
	const chart = useRef(null)

	const [best, setBest] = useState(0)
	const [FBI, setFBI] = useState(0)
	const [first, setFirst] = useState(0)
	const [last, setLast] = useState(0)
	const [options, setOptions] = useState({})
  const [peakPerf, setPeakPerf] = useState(0)
  const [, setSessions] = useState([])

	const label_background_color = '#CCC2'


	function strip_lines(GT, RGT, Peak) {
		const result = []

		if (GT && showMinimumTrigger) result.push({
			value: GT,
			label: 'Minimum Trigger',
			labelAlign: 'near',
			labelBackgroundColor: label_background_color,
			lineDashType: 'dot',
			labelFontColor: 'black',
			labelFontSize: 20,
			labelFontWeight: 'bolder',
			color: "black",
			showOnTop: true,
			thickness: 2,
		})
		
		if (RGT) result.push({
			value: RGT,
			label: 'Trigger',
			labelAlign: 'center',
			labelBackgroundColor: label_background_color,
			lineDashType: 'dash',
			labelFontColor: 'black',
			labelFontSize: 20,
			labelFontWeight: 'bolder',
			color: "black",
			showOnTop: true,
			thickness: 2,
		})
		
		if (Peak) result.push({
			value: Peak,
			label: 'Peak',
			labelAlign: 'far',
			labelBackgroundColor: label_background_color,
			labelFontWeight: 'bolder',
			labelFontColor: 'black',
			labelFontSize: 22,
			color: "#0007",
			showOnTop: true,
			thickness: 3,
		})
		
		return result
	}
	
	
	function strip_columns(Best) {
		const result = []
		
		if (Best) result.push({
			value: Best,
			label: 'Best',
			labelAlign: 'far',
			labelBackgroundColor: label_background_color,
			labelFontWeight: 'bolder',
			labelFontColor: 'black',
			labelFontSize: 22,
			color: "#FF07",
			showOnTop: true,
			thickness: 13,
		})
		
		return result
	}


	const tool_tip = E=>{
		let str = ''

		for (let i = 0; i < E.entries.length; i++) {
			let when = (E.entries[i].dataPoint.x)
			let what = (E.entries[i].dataPoint.y)

			when = (new Date(when)).toLocaleDateString()
			what = +what.toFixed(1)
			str = str.concat(`<span class="mini-chart-tooltip">${when}: ${what}</span>`)
		};
		
		return (str);
	}


	useEffect(() => {
		if (data && data.length) {		
			const dataByForce = data.slice()
			
			dataByForce.sort((a,b)=>{
				if (a.force < b.force) {
					return -1;
				}
				if (a.force > b.force) {
					return 1;
				}
				return 0;
			})


			const dataByMOB = data.slice()

			dataByMOB.sort((a,b)=>{
				if (a.mob < b.mob) {
					return -1;
				}
				if (a.mob > b.mob) {
					return 1;
				}
				return 0;
			})


			const dataByDate = data.slice()
			
			dataByDate.sort((a,b)=>{
				if (a.timestamp < b.timestamp) {
					return -1;
				}
				if (a.timestamp > b.timestamp) {
					return 1;
				}
				return 0;
			})


			const bestElement = {
				force: dataByForce[dataByForce.length-1].force.toFixed(1),
				mob: dataByMOB[dataByMOB.length-1].mob.toFixed(1),
			}

			
			const firstElement = {
				force: dataByDate[0].force.toFixed(1),
				mob: dataByDate[0].mob.toFixed(1),
			}
			
			
			const lastElement = {
				force: dataByDate[dataByForce.length-1].force.toFixed(1),
				mob: dataByDate[dataByMOB.length-1].mob.toFixed(1),
			}


			setBest(bestElement[mode])
			setFirst(firstElement[mode])
			setLast(lastElement[mode])

			
			const newFBI = Math.round((bestElement.force/firstElement.force)*100)-100

			setFBI(newFBI)


			let bestDate = null

			if (mode === 'force') bestDate = dataByForce[dataByForce.length-1].timestamp
			if (mode === 'mob') bestDate = dataByMOB[dataByMOB.length-1].timestamp
			
			const stripLines = strip_lines(levels.gt, levels.rgt, peakPerf)

			const stripColumns = strip_columns(bestDate)


      if (dataByDate) {
        setOptions({
          animationEnabled: true,
          axisX: {
            labelFormatter: function (e) {
              return CanvasJS.formatDate( e.value, "MMM");
            },
						labelFontSize: 20,
						stripLines: stripColumns,
					},

          axisY: {
						// includeZero: true,
						labelFontSize: 20,
						maximum: bestElement[mode] * 1.2,
						stripLines: stripLines,
          },

          data: [{
						color: '#EEB735',
						name: 'MOB',
            type: "splineArea",
            connectNullData: true,
            //nullDataLineDashType: "solid",
            xValueType: "dateTime",
            xValueFormatString: "YY MMM DD hh:mm TT",
            yValueFormatString: "#0.0",
            dataPoints: dataByDate
          }],

					toolTip: {
						contentFormatter: tool_tip
					},
        })
      }
	  }
	}, [data, levels, mode, peakPerf])


  useEffect(() => {
    let end = new Date(endDate)
    let start = new Date(startDate)

		const mobBasedResult = mode === 'mob'

    setPeakPerf(peakPerformance(data, mobBasedResult))

    let temp = data
    .filter(el=>((el.x >= start) && (el.x <= end)))

    setSessions(temp)
  }, [data, endDate, startDate])


	return (
		<div className={`mini chart outside-container ${breakAfter? 'page-break': ''}`}>
      <div className="mini chart inside-container">
        <div className="mini chart title">
					<div>
						<span className="mini chart name">{title.toUpperCase()}</span>
						&nbsp;
						<span className="mini chart performance separator">{FBI}%</span>
					</div>

					<div>
						<span className="mini chart name">First</span>
						<span className="mini chart performance separator">{first}</span>
					</div>

					<div>
						<span className="mini chart name">Best</span>
						<span className="mini chart performance separator">{best}</span>
					</div>

					<div>
						<span className="mini chart name">Last</span>
						<span className="mini chart performance separator">{last}</span>
					</div>
        </div>

        <CanvasJSChart
          onRef={ref=>chart.current=ref}
          className="base chart"
          options={options}
        />
      </div>
		</div>
	)
}


export default MiniChart