import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import CheckIn from "./CheckIn";
import Location from './Location'
import Login from './user/Login'
import Logout from './user/Logout'
import Navigator from "./Navigator";
import UserCode from './UserCode'
import Sessions from './Sessions'
import Profile from './Profile'
import Report from './Report'
import Appraisal from './Progress/Progress'
import Progress from './Progress'
import useSessionStorage from "./hook/useSessionStorage";

import loadingIcon from './img/loading.gif';

import './asset/bootstrap.min.css'
import './asset/bootstrap-reboot.min.css'
import './App.css';


function App() {
  sessionStorage.setItem('loading', false)

  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [, setView] = useState('login')
  const [loading,] = useSessionStorage('loading', false)


  function PrivateRoute({ children, ...rest }) {
    return (authenticated && user)
      ? (children)
      : (
        <Login
          setAuthenticated={setAuthenticated}
          setUser={setUser}
        />
      )
  }
  
  const loadingIconStyle = () => {
    if (loading) return { display: 'inline-block' }
    else return { display: 'none' }
  }
  
  
  return (
    <Router>
      <img
        alt=""
        className="loading icon"
        height="48"
        width="48"
        src={loadingIcon}
        style={loadingIconStyle()}
      />

      <div className="main content">
        <Routes>
          <Route
            exact
            path="/"
            element={<>
              <Navigator authenticated={authenticated} />
              <Login
                setAuthenticated={setAuthenticated}
                
                setUser={setUser}
              />
            </>}
          ></Route>
          
          <Route path="/report" element={<Report />} />
          
          <Route path="/progress" element={<Progress />} />
          
          <Route
            path="/checkIn"
            element={<PrivateRoute>
              <Navigator authenticated={authenticated} />
              <h1 className="row justify-content-center col-auto">Check In</h1>
              <Location
                user={user}
                showMap={false}
              />
              <br />
              <CheckIn user={user} />
              <br />
              <UserCode user={user} />
            </PrivateRoute>}
          ></Route>
          
          <Route
            path="/code"
            element={<PrivateRoute>
              <Navigator authenticated={authenticated} />
              <h1 className="row justify-content-center col-auto">QR Code</h1>
              <UserCode user={user} />
            </PrivateRoute>}
          ></Route>
          
          <Route
            path="/location"
            element={<PrivateRoute>
              <Navigator authenticated={authenticated} />
              <h1 className="row justify-content-center col-auto">Your OsteoStrong</h1>
              <Location user={user} />
            </PrivateRoute>}
          ></Route>

          <Route
            path="/logout"
            element={<PrivateRoute>
              <Logout
                auth={authenticated}
                setAuth={setAuthenticated}
                setUser={setUser}
                setView={setView}
              />
            </PrivateRoute>}
          >
          </Route>

          <Route
            path="/profile"
            element={<PrivateRoute>
              <Navigator authenticated={authenticated} />
              <UserCode user={user} />
              <br />
              <Profile user={user} />
            </PrivateRoute>}
          ></Route>

          <Route
            path="/data"
            element={<PrivateRoute>
              <Navigator authenticated={authenticated} />
              <Sessions user={user} />
            </PrivateRoute>}
          >
          </Route>

          <Route
            path="/appraisal"
            element={<PrivateRoute>
              <Navigator authenticated={authenticated} />
              <Appraisal user={user} />
            </PrivateRoute>}
          >
          </Route>
        </Routes>
      </div>
    </Router>
  )
}


export default App