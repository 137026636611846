import React,{useEffect} from 'react'
import QR from 'qrcode'

function UserCode({user}) {
  useEffect(() => {
    if (user) {
      const canvas = document.getElementById('qr-code')

      let codeData = 'https://osteostrong.me'

      codeData = JSON.stringify({
        code: user.code,
        email: user.email,
        login_id: user.login_id,
        phone: user.phone,
      })

      QR.toCanvas(canvas, codeData)
    }
  }, [user])

  return (
    <section className="row justify-content-center col-auto">
      {/* <h3>Your QR Code</h3> */}
      {/* <div> */}
      <canvas id="qr-code"></canvas>
      {/* </div> */}
    </section>
  )
}

export default UserCode