import React,{useState} from 'react'
import { Link } from "react-router-dom";
import {Navbar,Nav,} from 'react-bootstrap'

import logo from './img/android-chrome-384x384.png';


function Navigator({authenticated,setUser,user,history}) {
  const [navbarExpand, setNavbarExpand] = useState(false)

  const toggler = event=>{
    setNavbarExpand(navbarExpand ? false : "expanded")
  }
  
  const unexpand = event=>{
    setNavbarExpand(false)
  }

  const PrivateNavLink = ({activeClassName,children,className,show=true,to})=>{
    if (show) return <Nav.Link
      // activeClassName={activeClassName} 
      as={Link}
      className={className}
      onClick={unexpand}
      to={to} 
    >
      {children}
    </Nav.Link>
    else return <></>
  }

  return (
    <Navbar
      bg="light"
      collapseOnSelect
      expand="sm"
      expanded={navbarExpand}
      fixed="top"
      onToggle={toggler}
      // onSelect={toggler}
      sticky="top"
      variant="light"
    >
      <Navbar.Brand href="#home">
        <img
          alt=""
          height="30"
          width="30"
          src={logo}
          className="d-inline-block align-top"
        />{' '}
        OsteoStrong
      </Navbar.Brand>

      <Navbar.Toggle 
        aria-controls="responsive-navbar-nav" 
      />
      
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <PrivateNavLink
            className="nav-item nav-link"
            eventKey="0"
            to="/"
          >Home</PrivateNavLink>

          <PrivateNavLink
            className="nav-item nav-link"
            show={authenticated}
            to="/location"
          >Location</PrivateNavLink>

          <PrivateNavLink
            className="nav-item nav-link"
            show={authenticated}
            to="/checkIn"
          >Check-In</PrivateNavLink>

          <PrivateNavLink
            className="nav-item nav-link"
            show={authenticated}
            to="/data"
          >Data</PrivateNavLink>

          <PrivateNavLink
            className="nav-item nav-link"
            show={authenticated}
            to="/appraisal"
          >Progress</PrivateNavLink>

          <PrivateNavLink
            className="nav-item nav-link"
            show={authenticated}
            to="/profile"
          >Profile</PrivateNavLink>

          {/* <PrivateNavLink
            className="nav-item nav-link" show={authenticated} to="/code">QR Code</PrivateNavLink> */}

          <PrivateNavLink
            className="nav-item nav-link"
            eventKey="99"
            to="/logout"
          >Logout</PrivateNavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}


export default Navigator