import React from 'react'

function Legend() {
  function LegendItem({color, text}) {
    const styling = {
      color: color,
      fontSize: '1.2rem',
    }

    return <div className="col" style={styling}>* {text}</div>
  }

  return (
    <>
      {/* <LegendItem
        color="grey"
        text="First Session"
      /> */}
      {/* <LegendItem
        color="grey"
        text="Above Growth Trigger"
      /> */}
      <LegendItem
        color="white"
        text="Above Rapid Growth Trigger"
      />
      <LegendItem
        color="#EEB735"
        text="Peak Performance"
      />
      <LegendItem
        color="grey"
        text="Absence"
      />
    </>
  )
}

export default Legend
