import React,{useEffect,useRef,useState} from 'react'
import CanvasJSReact from '../../lib/canvasjs.react'
import {peakPerformance} from "../../lib/helpers";

const CanvasJSChart = CanvasJSReact.CanvasJSChart
const CanvasJS = CanvasJSReact.CanvasJS


function MiniChart({
	breakAfter=false,
	data,
	endDate,
	levels,
	mode='mob',
	showMinimumTrigger=false,
	startDate,
	title='GT'
}) {
	const chart = useRef(null)

	const [best, setBest] = useState(0)
	const [FBI, setFBI] = useState(0)
	const [first, setFirst] = useState(0)
	const [last, setLast] = useState(0)
	const [options, setOptions] = useState({})
  const [peakPerf, setPeakPerf] = useState(0)
  const [, setSessions] = useState([])

	const label_background_color = '#CCC2'


	const tool_tip = E=>{
		let str = '<span class="mini-chart-tooltip">'
		
		for (let i = 0; i < E.entries.length; i++) {
			let when = (E.entries[i].dataPoint.timestamp)
			let what = (E.entries[i].dataPoint.y)
			
			when = new Date(when)
			
			if (when?.getDate()) str += when.toLocaleDateString() + ': '
			
			
			what = +what.toFixed(1)
			
			str += what
		}
		
		str += '</span>'
		
		return (str)
	}


	useEffect(() => {
		if (data && data.length) {		
			const dataByForce = data.slice()
			
			dataByForce.sort((a,b)=>{
				if (a.force < b.force) {
					return -1;
				}
				if (a.force > b.force) {
					return 1;
				}
				return 0;
			})
			
			
			const dataByMOB = data.slice()
			
			dataByMOB.sort((a,b)=>{
				if (a.mob < b.mob) {
					return -1;
				}
				if (a.mob > b.mob) {
					return 1;
				}
				return 0;
			})


			let dataByDate = data.slice()
			
			dataByDate.sort((a,b)=>{
				if (a.timestamp < b.timestamp) {
					return -1;
				}
				if (a.timestamp > b.timestamp) {
					return 1;
				}
				return 0;
			})


			const bestElement = {
				force: dataByForce[dataByForce.length-1],
				mob: dataByMOB[dataByMOB.length-1],
			}

			
			const firstElement = {
				force: dataByDate[0],
				mob: dataByDate[0],
			}
			
			
			const lastElement = {
				force: dataByDate[dataByForce.length-1],
				mob: dataByDate[dataByMOB.length-1],
			}


			setBest(bestElement[mode][mode].toFixed(1))
			setFirst(firstElement[mode][mode].toFixed(1))
			setLast(lastElement[mode][mode].toFixed(1))

			
			const newFBI = Math.round((bestElement[mode].force/firstElement[mode].force)*100)-100

			setFBI(newFBI)


			let bestDate = null

			if (mode === 'force') bestDate = dataByForce[dataByForce.length-1].timestamp
			if (mode === 'mob') bestDate = dataByMOB[dataByMOB.length-1].timestamp


			const chartData = []

			chartData.push({
				color: 'grey',
				label: 'First',
				timestamp: firstElement[mode].timestamp,
				y: firstElement[mode][mode],
			})

			chartData.push({
				color: 'white',
				label: 'Best',
				timestamp: bestElement[mode].timestamp,
				y: bestElement[mode][mode],
			})
			
			chartData.push({
				label: 'Last',
				timestamp: lastElement[mode].timestamp,
				y: lastElement[mode][mode],
			})
			
			if (showMinimumTrigger) {
				chartData.push({
					color: '#EEB735AA',
					label: 'GT',
					y: levels.gt,
				})
			}
			
			chartData.push({
				label: 'Peak',
				y: peakPerf,
			})
			
			chartData.push({
				color: '#EEB735AA',
				label: 'RGT',
				y: levels.rgt,
			})


			chartData.sort((a,b)=>{
				if (a.y > b.y) return 1
				if (a.y < b.y) return -1
				return 0
			})

			
      if (chartData) {
        setOptions({
          animationEnabled: true,
          axisX: {
						labelFontSize: 20,
					},

          axisY: {
						labelFontSize: 20,
						maximum: bestElement[mode][mode] * 1.2,
          },
					backgroundColor: 'antiquewhite',

          data: [{
						color: '#EEB735',
						name: 'MOB',
            type: "column",
            yValueFormatString: "#0.0",
            dataPoints: chartData
          }],

					toolTip: {
						contentFormatter: tool_tip
					},
        })
      }
		}
	}, [best, data, first, last, levels, mode, peakPerf])


  useEffect(() => {
    let end = new Date(endDate)
    let start = new Date(startDate)

		const mobBasedResult = mode === 'mob'

    setPeakPerf(peakPerformance(data, mobBasedResult))

    let temp = data
    .filter(el=>((el.x >= start) && (el.x <= end)))

    setSessions(temp)
  }, [data, endDate, startDate])


	return (
		<div className={`mini chart outside-container ${breakAfter? 'page-break': ''}`}>
			<div className="mini chart inside-container">
				{data?.length? <>
					<div className="mini chart title">
						<div>
							<span className="mini chart name">
								{title.toUpperCase()}
							</span>
							&nbsp;
							<span className="mini chart performance separator">{FBI}%</span>
						</div>
						
						<div>
							<span className="mini chart name">First</span>
							<span className="mini chart performance separator">{first}</span>
						</div>
					  
						<div>
							<span className="mini chart name">Best</span>
							<span className="mini chart performance separator">{best}</span>
						</div>
						
						<div>
							<span className="mini chart name">Last</span>
							<span className="mini chart performance separator">{last}</span>
						</div>
					</div>


					<CanvasJSChart
						onRef={ref=>chart.current=ref}
						className="base chart"
						options={options}
					/>
				</>
					:
				<>Select a machine to see progress</>
				}
			</div>
		</div>
	)
}


export default MiniChart