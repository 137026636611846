import React, {useEffect, useRef, useState} from 'react'
import baseline from './baseline'
import CanvasJSReact from '../../lib/canvasjs.react'
import useLocalStorage from '../../hook/useLocalStorage'

const CanvasJSChart = CanvasJSReact.CanvasJSChart
const CanvasJS = CanvasJSReact.CanvasJS

const dateSorter = (a, b)=>{
  if (a.timestamp < b.timestamp) {
    return -1;
  }
  if (a.timestamp > b.timestamp) {
    return 1;
  }
  return 0; // a must be equal to b
}


const orderSorter = (a, b)=>{
  if (a.grip_id < b.grip_id) return -1
  if (a.grip_id > b.grip_id) return 1
  
  if (a.order < b.order) return -1
  if (a.order > b.order) return 1
  
  return 0
}


export default function GripStrength({
  breakAfter=true,
  data,
}) {
  const [user, setUser] = useLocalStorage('user', null)
  const [stats, setStats] = useLocalStorage('member-stats', [])
  
  const leftChart = useRef(null)
	const rightChart = useRef(null)
  
  const [leftForce, setLeftForce] = useState(0)
  const [rightForce, setRightForce] = useState(0)
  const [leftCurve, setLeftCurve] = useState([])
  const [rightCurve, setRightCurve] = useState([])
	const [leftOptions, setLeftOptions] = useState({})
	const [rightOptions, setRightOptions] = useState({})
  
  const [leftRef, setLeftRef] = useState([12,13])
  const [rightRef, setRightRef] = useState([13,14])


	const toolTip = E=>{
		let str = '<span class="mini-chart-tooltip">'
    
		for (let i = 0; i < E.entries.length; i++) {
			let when = E.entries[i].dataPoint.order
			let what = E.entries[i].dataPoint.y
      
			// when = new Date(when)
			
      if (when) str += when + ', '
			
			what = +what.toFixed(1)
			
			str += what
		}
    
		str += '</span>'
		
		return (str)
	}


  const chartOptions = (curve, refRange, title)=>{
    let result = {}
    
    const stripLines = [{
			color: "#0F04",
			label: 'Demographic average',
			labelAlign: 'far',
			labelBackgroundColor: '#0F02',
			labelFontColor: 'black',
			labelFontSize: 20,
			showOnTop: true,
			thickness: refRange[1] - refRange[0],
			value: refRange[0],
		}]
    
    
    let curveMaxX = 0
    let curveMaxY = 0
    
    const chartData = curve.map((X,I)=>{
      if (X.force > curveMaxY) {
        curveMaxX = I
        curveMaxY = X.force
      }
      
      return {
        order: X.order,
        x: I,
        y: X.force,
      }
    })


    const bestMarkerWidth = 10

    const chartTop = Math.max(curveMaxY, refRange[1]) + 10

    const stripColumns = [{
      color: '#FF04',
      label: `Best ${title}`,
			labelAlign: 'far',
			labelBackgroundColor: '#FF04',
			labelFontColor: 'black',
			labelFontSize: 22,
			showOnTop: true,
			thickness: bestMarkerWidth,
			value: curveMaxX,
		}]


    if (chartData) {
      result = {
        animationEnabled: true,
        axisX: {
          labelFormatter: function (e) {
            // return CanvasJS.formatDate( e.value, "MMM");
            return ''
          },
          // labelFontSize: 20,
          stripLines: stripColumns,
        },
        
        axisY: {
          includeZero: true,
          labelFontSize: 20,
          maximum: chartTop,
          stripLines: stripLines,
        },

        data: [{
          color: '#EEB735',
          connectNullData: true,
          dataPoints: chartData,
          name: 'Grip',
          nullDataLineDashType: "dash",
          type: "splineArea",
          xValueType: "number",
          // xValueFormatString: "YY MMM DD hh:mm TT",
          yValueFormatString: "#0.0",
        }],

        title: {
          text: title,
        },
        toolTip: {
          contentFormatter: toolTip
        },
      }
    }
    
    return result
  }


  useEffect(() => {
    const age = (new Date()).getFullYear() - +stats.birthyear

    const baselineIndex = stats?.gender? stats.gender.toLowerCase(): 'female'
    const applicableBaseline = baseline[baselineIndex]


    let reference = {left:-7, right:-7}

    for (let range in applicableBaseline) {
      if (age >= range) reference = applicableBaseline[range]
    }

    setLeftRef(reference.left)
    setRightRef(reference.right)
  }, [stats, user])


  useEffect(() => {
    if (data?.data?.length) {
      const gripLeft = data.data.filter(X=>X.side==='left')
      const gripRight = data.data.filter(X=>X.side==='right')
      
      setLeftForce(gripLeft?.force || 0)
      setRightForce(gripRight?.force || 0)
      
      const leftIds = gripLeft?.map(X=>X.id)
      const rightIds = gripRight?.map(X=>X.id)
      
      
      let newLeftCurve = data.curve.filter(X=>leftIds.includes(X.grip_id))
      let newRightCurve = data.curve.filter(X=>rightIds.includes(X.grip_id))
      
      if (newLeftCurve.length > 20) {
        const newb = []
        const iter = Math.floor(newRightCurve.length / 20)
        for (let i = 0; i < newLeftCurve.length; i+=iter) {
          newb.push(newLeftCurve[i])
        }
        newLeftCurve = newb
      }
      
      if (newRightCurve.length > 20) {
        const newb = []
        const iter = Math.floor(newRightCurve.length / 20)
        for (let i = 0; i < newRightCurve.length; i+=iter) {
          newb.push(newRightCurve[i])
        }
        newRightCurve = newb
      }

      // sort the curves by timestamp
      newLeftCurve = newLeftCurve.sort(orderSorter)
      newRightCurve = newRightCurve.sort(orderSorter)
      
      
      setLeftCurve(newLeftCurve)
      setRightCurve(newRightCurve)
    }
  }, [data])


  useEffect(() => {
    if (leftCurve) setLeftOptions(chartOptions(leftCurve, leftRef, 'Left'))
  }, [leftCurve, leftForce])


  useEffect(() => {
    if (rightCurve) setRightOptions(chartOptions(rightCurve, rightRef, 'Right'))
  }, [rightCurve, rightForce])


  return (<>
    <div className={`mini chart outside-container posture ${breakAfter? 'page-break': ''}`}>
      <div className="mini chart inside-container">
        {(data)? <>
          <div className="mini chart title">
            <div>
              <span className="mini chart name">Grip-Strength</span>
              <span className="mini chart performance separator">Assessment</span>
            </div>

            <div>
              <span className="mini chart name">Left</span>
              <span className="mini chart performance separator">{leftForce}#</span>
            </div>

            <div>
              <span className="mini chart name">Right</span>
              <span className="mini chart performance separator">{rightForce}#</span>
            </div>
          </div>

          <CanvasJSChart
            onRef={ref=>rightChart.current=ref}
            className="base chart"
            options={rightOptions}
          />

          <CanvasJSChart
            onRef={ref=>leftChart.current=ref}
            className="base chart"
            options={leftOptions}
          />
        </>
        :
          <>No grip data</>
        }
      </div>
    </div>
  </>)
}