import React, {createRef, useRef, useState} from 'react'

// import { useScreenshot } from 'use-react-screenshot'
import {post, screenCapture} from '../lib/helpers'


export default function ReportShare({user}) {
  const captureRef = createRef(null)
  const shareEmail = useRef('')

  // const [image, takeScreenshot] = useScreenshot()

  // const [pageImage, setPageImage] = useState(null);
  // const [showShare, setShowShare] = useState(false)
  const [state, setState] = useState(0)


  const createFileName = (ext,name)=>`${name}.${ext}`


  const download = (image, { name = "report", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };


  // const getImage = () => takeScreenshot(captureRef.current).then(download)


  // async function captureSiteImage(event) {
  //   const img = await screenCapture()

  //   setPageImage(img)
  // }


  function shareClickHandler(event) {
    setState(state+1)
  }


  function shareMemberReport(event) {
    event.preventDefault()

    const payload = {
      recipient: shareEmail.current.value
    }

    
    post(`member/email/doctor/${user.id}`, payload)
    .then(res=>{
      setState(state+1)
    })
    .catch(console.error)

    return false
  }


  if (state === 0) {
    return <button className="sharedoc button print-hide" onClick={shareClickHandler}>Share My Progress</button>
  } else if (state === 1) {
    return <>
      {/* 
      <button onClick={getImage} type="button">Screenshot</button>

      <button onClick={captureSiteImage} type="button">Screen Capture</button>
      */}

      <form className="sharedoc form print-hide" onSubmit={shareMemberReport}>
        <input ref={shareEmail} placeholder="recipient's email" />
        <button>Submit</button>
      </form> 
    </>
  } else if (state === 2) {
    return <div className="sharedoc follower print-hide">A link was sent!</div>
  }
}