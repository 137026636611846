import React, { useEffect, useState } from 'react'

function ExerciseStats({ data, levels, origin }) {
  const [functionalIncrease, setFunctionalIncrease] = useState(0)
  const [peakPerf, setPeakPerf] = useState(0)
  const [tonnage, setTonnage] = useState(0)
  const [totalSessions, setTotalSessions] = useState(0)
  const [totalGT, setTotalGT] = useState(0)
  const [totalRGT, setTotalRGT] = useState(0)
  const [totalPeak, setTotalPeak] = useState(0)

  function peakData(data) {
    const peakLength = 10
    
    let theData = data.slice()

    theData = theData
    .sort((a, b) => {
      if (a.mob < b.mob) {
        return 1;
      }
      if (a.mob > b.mob) {
        return -1;
      }
      return 0; // a must be equal to b
    })
    .slice(0, peakLength)
    
    let peak = 0
    theData.forEach(el=>peak+=el.mob)
    peak /= peakLength
    // avg = Math.round((avg / peakLength)*100)/100

    return peak * 0.85
  }

  useEffect(() => {
    if (data) {
      /* Peak Performance */
      const peakPerf = Math.round(peakData(data) * 10) / 10
      setPeakPerf(peakPerf)

      /* Functional Performance Increase */
      const valueMax = data.reduce((a, el) => (!a || el.mob > a) ? el.mob : a, 0)
      const valueMin = data.reduce((a, el) => (!a || el.mob < a) ? el.mob : a, 0)
      const percentIncrease = Math.round((valueMax / valueMin) * 100) - 100

      setFunctionalIncrease(percentIncrease)

      /* Sessions Break-down */
      setTotalSessions(data.length)
      setTotalGT(data.filter(el=>(el.mob > levels.gt)).length)
      setTotalRGT(data.filter(el=>(el.mob > levels.rgt)).length)
      setTotalPeak(data.filter(el=>(el.mob > peakPerf)).length)

      /* Tonnage */
      let tons = 0
      data.forEach(el=>tons+=el.force)
      tons = Math.round(tons/200) / 10
      setTonnage(tons)
    }
  }, [data])

  function DataRow({ name, value }) {
    return <tr>
      <td className="label">{name}</td>
      <td className="value">{value}</td>
    </tr>
  }

  return (
    <div>
      <h2>{origin.capitalize()} GT</h2>
      <div className="container">
        <div className="row">
          <div className="col">
            <table className="stats">
              <tbody>
                <DataRow name="Functional Increase" value={`${functionalIncrease}%`} />
                <DataRow name="Peak Performance" value={peakPerf} />
                <DataRow name="Machine Tonnage" value={tonnage} />
              </tbody>
            </table>
          </div>
          <div className="col">
            <table className="stats">
              <tbody>
                <DataRow name="Sessions @ GT" value={totalGT} />
                <DataRow name="Sessions @ RGT" value={totalRGT} />
                <DataRow name="Sessions @ Peak" value={totalPeak} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExerciseStats