import React,{useEffect,useState} from 'react'
import { useLocation } from "react-router-dom";
import {post} from '../lib/helpers'

import Report from "./Report";

import './report.css'
import './report-header.css'
import './report-news.css'


export default function({
	
}) {
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
  
	const query = useQuery()
  
	const [authentic, setAuthentic] = useState(false)
	const [code, setCode] = useState('')
	const [email, setEmail] = useState('')
	const [error, setError] = useState('Loading...')
	const [phone, setPhone] = useState('')
	const [token, setToken] = useState(null)
	const [user, setUser] = useState(null)
  
  
	const setUsefulError = err=>{
		if (!email || !email.length) setError('Invalid Email')
		else if (!phone || !phone.length) setError('Invalid Phone')
		else if (!code || !code.length) setError('Invalid Code')
		else if (err) setError(err)
		else setError('Invalid Login')
	}
  
	const errorHandler = error=>{
		console.error(error)
		
		setUser({})
		setAuthentic(false)
		setUsefulError(error.message)
	}
  
	
	const resultHandler = (result)=>{
		if (result.user && result.token) {
			if (result.user) setUser(result.user)
			if (result.token) setToken(result.token)
			
			sessionStorage.setItem('jwt_token', result.token)
			
			setAuthentic(true)
		} else {
			setUser({})
			setAuthentic(false)
			sessionStorage.setItem('jwt_token', '')
			setUsefulError(result.error)
		}
	}
  
  
	useEffect(() => {
		const q = window.location.search.slice(1).split('&')
		const p = {}
		
		for (let sub of q) {
			const thisn = sub.split('=')
			p[thisn[0]] = thisn[1]
		}
		
		setEmail(p.email)
		setPhone(p.phone)
		setCode(p.code)
	}, [query])
  
	
	useEffect(() => {
		if ((code && email && phone)
		&& (code.length && email.length && phone.length)) {
			const credentials = {
				email: email,
				phone: phone,
				code: code,
			}
			
			setError('Loading...')
			post(`member/login`, credentials)
			.then(result=>{
				resultHandler(result)
			})
			.catch(errorHandler)
		} else {
			setUsefulError()
		}
	}, [code,email,phone])
  
  
	if (authentic) return (<>
		<Report user={user} />
	</>)
	else return (<h1>{error}...</h1>)
}