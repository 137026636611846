import React,{useEffect, useState} from 'react'

import {localeWeight, post} from '../lib/helpers'

import BalanceReport from "./Balance";
import GripStrengthReport from './Grip';
import Header from "./Header";
import MiniNews from "./MiniNews";
import PainReport from './Pain'
import PostureReport from "./Posture";
import ReportShare from "./ReportShare";
import SpectrumFeedback from "./Spectrum";
import useLocalStorage from "../hook/useLocalStorage";

import banner_img from '../img/horiz/OsteoStrong_HorizontalLogo_Color.png'
import printer_icon from "../img/icon/printer.png";
import calendar_icon from "../img/icon/calendar.png";


function Progress({user}) {
  const [stats, setStats] = useLocalStorage('member-stats', [])
  
  const [bodyWeight, setBodyWeight] = useState(100)
  const [sessionData, setSessionData] = useState(null)
	const [data_balance, setData_balance] = useState([])
	const [data_core, setData_core] = useState([])
	const [data_grip, setData_grip] = useState([])
	const [data_lower, setData_lower] = useState([])
	const [data_pain, setData_pain] = useState([])
	const [data_postural, setData_postural] = useState([])
	const [data_posture, setData_posture] = useState([])
	const [data_upper, setData_upper] = useState([])
  const [mode, setMode] = useState('mob')
  const [news, setNews] = useState({})
  const [rawAssessmentData, setRawAssessmentData] = useState(null)
  const [rawSessionData, setRawSessionData] = useState(null)
  // const [convertedWeight, setConvertedWeight] = useState(X=>X)
  
  const now = (new Date()).toLocaleDateString()
  
  let retrieving = false
  
  // bar-levels are initially set to MOB levels ~ later we calculate force-levels as needed
  const [coreLevels, setCoreLevels] = useState({
    gt: 1.0,
    rgt: 1.5,
  })
  const [lowerLevels, setLowerLevels] = useState({
    gt: 4.2,
    rgt: 7.2,
  })
  const [posturalLevels, setPosturalLevels] = useState({
    gt: 2.0,
    rgt: 2.5,
  })
  const [upperLevels, setUpperLevels] = useState({
    gt: 2.0,
    rgt: 2.5,
  })
  
  
  function exerciseSessions(data, exercise) {
    let result = data.filter(el=>(el.origin.toLowerCase()===exercise.toLowerCase()))
    .sort((a, b)=>{
      if (a.timestamp < b.timestamp) {
        return -1;
      }
      if (a.timestamp > b.timestamp) {
        return 1;
      }
      return 0; // a must be equal to b
    })
    
    result.forEach((el,I,A)=>{
      const thisn = A[I]
      
      thisn.color = '#EEB735'
    })
    
    return result
  }
  
  
  function printReport() {
    window.print()
  }
  
  
  function processAssessmentData(data) {
    setData_balance({
      data: data.balance,
      curve: data.balanceCurve,
    })
    
    setData_grip({
      data: data.grip,
      curve: data.gripCurve,
    })
    
    setData_pain(data.pain)
    
    if (data?.posture?.data) {
      setData_posture(data.posture)
    } else {
      setData_posture(null)
    }
  }
  
  
  function processSessionData(result) {
    let stuff = result?.filter(el=>el.valid)
    .map((el,I)=>{
      const thisTimestamp = new Date(el.timestamp)
      
      
      return {
        id: el.id,
        mob: el.data_float,
        force: localeWeight(el.data_int),
        key: el.id,
        origin: el.origin,
        timestamp: new Date(el.timestamp),
        x: thisTimestamp,
        y: (mode==='mob')? el.data_float: localeWeight(el.data_int),
      }
    })


    stuff = {
      core: exerciseSessions(stuff, 'core'),
      lower: exerciseSessions(stuff, 'lower'),
      postural: exerciseSessions(stuff, 'postural'),
      upper: exerciseSessions(stuff, 'upper'),
    }

    setSessionData(stuff)
    setData_core(stuff.core)
    setData_lower(stuff.lower)
    setData_postural(stuff.postural)
    setData_upper(stuff.upper)
    setData_pain(stuff.pain)
  }
  
  
  function setTriggerLevels() {
    if (user && bodyWeight && mode) {
      if (mode === 'mob') {
        coreLevels.gt = 1.0
        coreLevels.rgt = 1.5
        setCoreLevels(coreLevels)
        
        lowerLevels.gt = 4.2
        lowerLevels.rgt = 7.2
        setLowerLevels(lowerLevels)
        
        posturalLevels.gt = 2.0
        posturalLevels.rgt = 2.5
        setPosturalLevels(posturalLevels)
        
        upperLevels.gt = 2.0
        upperLevels.rgt = 2.5
        setUpperLevels(upperLevels)
      } else {
        coreLevels.gt = 1.0 * bodyWeight
        coreLevels.rgt = 1.5 * bodyWeight
        setCoreLevels(coreLevels)
        
        lowerLevels.gt = 4.2 * bodyWeight
        lowerLevels.rgt = 7.2 * bodyWeight
        setLowerLevels(lowerLevels)
        
        posturalLevels.gt = 2.0 * bodyWeight
        posturalLevels.rgt = 2.5 * bodyWeight
        setPosturalLevels(posturalLevels)
        
        upperLevels.gt = 2.0 * bodyWeight
        upperLevels.rgt = 2.5 * bodyWeight
        setUpperLevels(upperLevels)
      }
    }
  }
  
  
  async function retrieveData(abortSessionData, abortAssessmentData, abortStats) {
    try {
      let body = {user_id:user.id}
      
      retrieving = true
      
      
      const sessionResult = await post('member/sessionData', body, abortSessionData.signal)
      
      setRawSessionData(sessionResult)
      sessionResult? processSessionData(sessionResult): processSessionData([])


      const assessmentResult = await post('member/assessmentData', body, abortAssessmentData.signal)

      setRawAssessmentData(assessmentResult)
      processAssessmentData(assessmentResult)


      const statsResult = await post('member/stats', body, abortStats.signal)

      setStats(statsResult)


      retrieving = false
    } catch (error) {
      console.error(error)
    }
  }


	useEffect(() => {
    const abortSessionData = new AbortController()
    const abortAssessmentData = new AbortController()
    const abortStats = new AbortController()
    
    
    if (user && user.id && !retrieving) {
      retrieveData(abortSessionData, abortAssessmentData, abortStats)
      .catch(console.error)
    }
    
    
    return ()=>{
      abortSessionData.abort()
      abortAssessmentData.abort()
      abortStats.abort()
    }
  }, [user])
  
  
  useEffect(() => {
    setTriggerLevels()
    
    if (rawSessionData) processSessionData(rawSessionData)
  }, [bodyWeight, mode, user])
  
  
  useEffect(() => {
    setTriggerLevels()
  }, [sessionData])
  
  
  useEffect(() => {
    if (stats?.weight) {
      // user.body_weight = +stats.weight
      setBodyWeight(+stats.weight)
    }
  }, [stats])


	return ( <div className="report">
    <div className="banner">
      <img alt="" className="logo" src={banner_img} />
    </div>
    
    <div className="banner print-hide">
      <div className="report date">
        <img alt="" src={calendar_icon} />
        <span>{now}</span>
      </div>
      
      <ReportShare user={user} />
      
      <button className="print button print-hide" onClick={printReport}>
        <img alt="" src={printer_icon} />        
        Print
      </button>
    </div>
    
    <hr />
    
    <Header data={sessionData} user={user} />

    {(news && news.left)?
      <MiniNews
        news={news.left}
      />
      :<></>
    }
    
    {(news && news.right)?
      <MiniNews
        news={news.right}
      />
      :<></>
    }

    <SpectrumFeedback
      upperLevels={upperLevels}
      lowerLevels={lowerLevels}
      coreLevels={coreLevels}
      posturalLevels={posturalLevels}
      upperData={data_upper}
      lowerData={data_lower}
      coreData={data_core}
      posturalData={data_postural}
      mode={mode}
      stats={stats}
    />

    <BalanceReport data={data_balance} />

    <PostureReport data={data_posture} />

    <GripStrengthReport data={data_grip} />

    <PainReport data={data_pain} />
	</div> )
}


export default Progress