import querystring from 'querystring'
import React,{useEffect,useRef,useState} from 'react'
import {useNavigate} from 'react-router-dom'
import './Login.css'

import {post} from '../lib/helpers'


function Login({setAuthenticated,user,setUser}) {
  const Parm = querystring.parse(window.location.search.slice(1))
  
  const code = useRef(Parm.code)
  const codeLabel = useRef(null)
  const email = useRef(Parm.email)
  const emailButton = useRef(null)
  const feedback = useRef(null)
  const phone = useRef(Parm.phone)
  const phoneLabel = useRef(null)
  
  const navigate = useNavigate()

  const [thisn, setThisn] = useState('LOGIN')
  
  let credentials = {}
  
  useEffect(() => {
    if (Parm.code && Parm.code.length) code.current.value = Parm.code
    if (Parm.email && Parm.email.length) email.current.value = Parm.email
    if (Parm.phone && Parm.phone.length) phone.current.value = Parm.phone

    autoLogin()
  }, [user])

  const errorHandler = error=>{
    console.error(error)

    if (feedback && feedback.current) feedback.current.textContent = error.message || 'unable to login'

    localStorage.setItem('credentials', '{}')
    setUser({})
  }

  const resultHandler = (result,creds,redirectAfter)=>{
    if (result.user) setUser(result.user)
    if (result.error) feedback.current.textContent = result.error
    if (result.token) sessionStorage.setItem('jwt_token', result.token)

    localStorage.setItem('user', JSON.stringify(result.user))
    localStorage.setItem('credentials', JSON.stringify(creds))

    setAuthenticated(true)
    if (redirectAfter) navigate('/data')
  }

  const autoLogin = creds=>{
    let savedCreds = JSON.parse(localStorage.getItem('credentials'))

    if (savedCreds && savedCreds.email && savedCreds.phone && savedCreds.code) {
      post(`member/login`, savedCreds)
      .then(result=>{
        resultHandler(result, savedCreds, false)
      })
      .catch(errorHandler)
    }
  }

  const formLogin = event=>{
    if (event) event.preventDefault()

    credentials = {
      email: email.current.value,
      phone: phone.current.value,
      code: code.current.value,
    }

    if (credentials.email && credentials.phone && credentials.code) {
      post(`member/login`, credentials)
      .then(result=>{
        resultHandler(result, credentials, true)
      })
      .catch(errorHandler)
    }    
  }

  const getCode = event=>{
    let credentials = {
      email: email.current.value,
      phone: phone.current.value,
    }

    post(`member/get_code`, credentials)
    .then(result=>{
      feedback.current.textContent = result.success
      setThisn('LOGIN')
    })
  }

  function Submitter({state}) {
    if (state === 'LOGIN') {
      if (code.current && code.current.style) code.current.style.display = 'inline-block'
      if (codeLabel.current && codeLabel.current.style) codeLabel.current.style.display = 'inline-block'

      return (
      <>
        <button className="btn btn-primary control" type="submit">Login</button>
        <br/>
        <br/>
        <a href="#" onClick={()=>setThisn('EMAIL')}>Don't have a code?  Click Here!</a>
      </>
      )
    } else if (state === 'EMAIL') {
      // code.current.value = ''
      code.current.style.display = 'none'
      codeLabel.current.style.display = 'none'
      
      return (
      <>
        <button 
          className="btn btn-warning control"
          ref={emailButton} 
          onClick={getCode} 
          type="button"
        >Email My Code!</button>
      </>
      )
    } else if (state === 'SMS') {
      return (
      <>
      </>
      )
    }
  }

  return (
    <section className="login row justify-content-center col-auto">
      <form autoComplete="on" onSubmit={formLogin}>
        <table>
        <tbody>
          <tr>
            <td>
              <label className="form-label" htmlFor="emailAddress">Email</label>
            </td>
            <td>
              <input className="form-control" id="emailAddress" name="email" ref={email} type="email" autoComplete="email" required />
            </td>
          </tr>
          <tr>
            <td>
              <label className="form-label" ref={phoneLabel} htmlFor="phoneNum">Phone</label>
            </td>
            <td>
              <input className="form-control" id="phoneNum" name="phone" ref={phone} type="tel" autoComplete="tel" required />
            </td>
          </tr>
          <tr>
            <td>
              <label className="form-label" ref={codeLabel} htmlFor="codeInput">Code</label>
            </td>
            <td>
              <input className="form-control" id="codeInput" name="code" ref={code} type="password" autoComplete="current-password" required />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Submitter state={thisn} />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div ref={feedback}></div>
            </td>
          </tr>
        </tbody>
        </table>
      </form>
    </section>
  )
}

export default Login