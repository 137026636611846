import React from 'react'

import CGT from '../../img/core_gt.png'
import LGT from '../../img/lower_gt.png'
import PGT from '../../img/postural_gt.png'
import UGT from '../../img/upper_gt.png'

import './MachineSelector.css'


export default function MachineSelector({
  machine,
  setMachine,
}) {
  return <div className='machine selector'>
    <img
      className={machine==='core'? 'highlight': ''}
      onClick={E=>setMachine('core')}
      src={CGT}
    />

    <img
      className={machine==='lower'? 'highlight': ''}
      onClick={E=>setMachine('lower')}
      src={LGT}
    />

    <img
      className={machine==='postural'? 'highlight': ''}
      onClick={E=>setMachine('postural')}
      src={PGT}
    />

    <img
      className={machine==='upper'? 'highlight': ''}
      onClick={E=>setMachine('upper')}
      src={UGT}
    />
  </div>
}